/*

 Custom colors for Giro.Tech organization

            **WARNING**
 Make sure to make changes in custom folder
 Changes made in source will be ignored

*/

$organization_primary: #3f41d1;
$organization_primary_focus: #32348d;
$organization_primary_disabled: #3f41d14d;

$organization_program_default: #406afa;

$organization_secondary: #072056;
$organization_secondary_focus: #784af4;

$organization_secondary_transparent: #030b1db3;

$organization_secundary_border: #486094;

$organization_secundary_link: #32aacf;
$organization_secundary_link_hover: #51cdf3;

$organization_secundary_button: #3f41d1;
$organization_secundary_button_focus: #32348d;
